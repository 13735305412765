import React from 'react'
import { withRouter } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'

@withRouter
class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
