import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {'x-admin': 'yes'}
});

instance.interceptors.request.use(config => {
  const token = window.localStorage.getItem('token');
  const tempData = window.localStorage.getItem('temp_data');
  const user = tempData ? JSON.parse(tempData) : null;
  const tokenTempData = user ? user.token : null;
  return {
    ...config,
    headers: {
      ...config.headers,
      'X-Auth-Token': tokenTempData || token,
    },
  };
});

instance.interceptors.response.use(
  response => response,
  error => {
    const response = error.response || {};
    const data = response.data || {};
    if (response.status === 401 && data.code === 'auth.token_invalid') {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('temp_data');
      window.location.href = '/#/user/login';
    }
    return Promise.reject(error);
  }
)

export default instance;
