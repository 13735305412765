import { all, takeEvery, put, select } from 'redux-saga/effects'
import store from 'store'
import actions from './actions'

export function* CHANGE_BRAND({ payload }) {
  const { brands } = yield select();
  let brand = {
    name: '',
    slug: '',
    logo: {},
    s3_path: '',
  };
  if (payload !== 'all') {
    brand = brands.data.find(item => item.slug === payload);
  }
  Object.keys(brand).forEach(key => {
    store.set(`app.brands.${key}`, brand[key]);
  })
  yield put({
    type: 'brands/SET_STATE',
    payload: { ...brand },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_BRAND, CHANGE_BRAND),
  ])
}
