import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd';
import { currentAccount } from 'services/user'
import token from 'services/token'
import ability, { defineRulesFor } from 'utils/ability';
import { BRAND_URL, COLLECTION_URL, LOGIN_URL, MFA_VERIFY_OTP_URL, MY_PERMISSION_URL } from 'utils/constants';
import API from 'api'
import { isEmpty } from 'lodash';
import store from 'store';
import actions from './actions'
import countries from '../../utils/s_countries';
import languages from '../../utils/languages';

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const { data } = yield call(API.post, LOGIN_URL, { email, password });
    if (!isEmpty(data)) {
      const user = token.parse(data.token);
      const role = user.role ? user.role.name : null;
      if (role && role !== 'registered') {
        if (user.mfaEnabled) {
          token.storeTemporaryData({ token: data.token, permissions: data.permissions });
          yield put({
            type: 'user/SET_STATE',
            payload: {
              loading: false,
              mfaEnabled: true,
            }
          });
        } else {
          token.save(data.token);
          token.savePermissions(data.permissions);
          yield put({ type: 'user/LOAD_CURRENT_ACCOUNT' });
        }
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
            error: true,
            message: 'You have not permission to access this area'
          },
        })
      }
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        error: true,
        message: error.response ? error.response.data.message : error.message
      },
    })
  }
}

export function* VERIFY_OTP({ payload }) {
  try {
    const { code } = payload;
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    yield call(API.post, `${MFA_VERIFY_OTP_URL}/${code}`);
    const user = token.getTemporaryData();
    token.save(user.token);
    token.savePermissions(user.permissions);
    yield put({ type: 'user/LOAD_CURRENT_ACCOUNT' });
  } catch (error) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        error: true,
        message: error.response.data.message || error.message
      },
    })
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      }
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield call(currentAccount)
  const data = token.data()
  const permissions = token.getPermissions()
  if (!isEmpty(data)) {
    ability.update(defineRulesFor(permissions))
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: data._id,
        firstName: data.firstName,
        lastName: data.lastName,
        name: [data.firstName, data.lastName].filter(item => !!item).join(' '),
        email: data.email,
        avatar: '',
        role: data.role.name,
        birthDate: data.birthDate,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      error: false,
      message: '',
    },
  })
}

export function* LOAD_CURRENT_PERMISSION() {
  const user = token.data();
  if (user.role) {
    const { data } = yield call(API.get, MY_PERMISSION_URL);
    if ((data.permissions || []).length > 0) {
      token.savePermissions(data.permissions);
    }
  }
}

export function* LOGOUT() {
  token.reset();
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      firstName: '',
      lastName: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      error: '',
      message: '',
      birthDate: '',
      mfaEnabled: false,
      authorized: false,
      loading: false,
    },
  })
}

export function* LOAD_COLLECTION() {
  if (token.hasPermission('collections')) {
    try {
      const { data } = yield call(API.get, COLLECTION_URL);
      data.countries = countries;
      data.languages = languages;
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(data)) {
        yield store.set(`app.collections.${key}`, value);
        yield put({ type: 'collections/SET_STATE', payload: { [key]: value } })
      }
    } catch (error) {
      notification.warning({
        message: 'Warning',
        description: error.response.data.message,
      })
    }
  }
}

export function* LOAD_BRAND() {
  if (token.hasPermission('brands')) {
    try {
      const { data } = yield call(API.get, BRAND_URL);
      yield store.set('app.brands.data', data);
      yield put({
        type: 'brands/SET_STATE',
        payload: {
          data,
        }
      });
    } catch (error) {
      notification.warning({
        message: 'Warning',
        description: error.response.data.message,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.VERIFY_OTP, VERIFY_OTP),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_COLLECTION),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_BRAND),
    takeEvery(actions.LOAD_COLLECTION, LOAD_COLLECTION),
    takeEvery(actions.LOAD_BRAND, LOAD_BRAND),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
    LOAD_CURRENT_PERMISSION(),
  ])
}
