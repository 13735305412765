export default [
  {
    "_id": "5df4e53656ca1b2460c4b1f2",
    "code": "DZ",
    "name": "Algeria",
    "id": "5df4e53656ca1b2460c4b1f2"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1f1",
    "code": "AL",
    "name": "Albania",
    "id": "5df4e53656ca1b2460c4b1f1"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1f3",
    "code": "AO",
    "name": "Angola",
    "id": "5df4e53656ca1b2460c4b1f3"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1f4",
    "code": "AR",
    "name": "Argentina",
    "id": "5df4e53656ca1b2460c4b1f4"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1f5",
    "code": "AM",
    "name": "Armenia",
    "id": "5df4e53656ca1b2460c4b1f5"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1f6",
    "code": "AU",
    "name": "Australia",
    "id": "5df4e53656ca1b2460c4b1f6"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1f7",
    "code": "AT",
    "name": "Austria",
    "id": "5df4e53656ca1b2460c4b1f7"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1f8",
    "code": "AZ",
    "name": "Azerbaijan",
    "id": "5df4e53656ca1b2460c4b1f8"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1f9",
    "code": "BS",
    "name": "Bahamas",
    "id": "5df4e53656ca1b2460c4b1f9"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1fa",
    "code": "BH",
    "name": "Bahrain",
    "id": "5df4e53656ca1b2460c4b1fa"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1fb",
    "code": "BD",
    "name": "Bangladesh",
    "id": "5df4e53656ca1b2460c4b1fb"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1fc",
    "code": "BY",
    "name": "Belarus",
    "id": "5df4e53656ca1b2460c4b1fc"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1fd",
    "code": "BE",
    "name": "Belgium",
    "id": "5df4e53656ca1b2460c4b1fd"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1fe",
    "code": "BO",
    "name": "Bolivia",
    "id": "5df4e53656ca1b2460c4b1fe"
  },
  {
    "_id": "5df4e53656ca1b2460c4b1ff",
    "code": "BW",
    "name": "Botswana",
    "id": "5df4e53656ca1b2460c4b1ff"
  },
  {
    "_id": "5df4e53656ca1b2460c4b200",
    "code": "BR",
    "name": "Brazil",
    "id": "5df4e53656ca1b2460c4b200"
  },
  {
    "_id": "5df4e53656ca1b2460c4b201",
    "code": "BN",
    "name": "Brunei",
    "id": "5df4e53656ca1b2460c4b201"
  },
  {
    "_id": "5df4e53656ca1b2460c4b202",
    "code": "BG",
    "name": "Bulgaria",
    "id": "5df4e53656ca1b2460c4b202"
  },
  {
    "_id": "5df4e53656ca1b2460c4b203",
    "code": "BF",
    "name": "Burkina Faso",
    "id": "5df4e53656ca1b2460c4b203"
  },
  {
    "_id": "5df4e53656ca1b2460c4b204",
    "code": "CM",
    "name": "Cameroon",
    "id": "5df4e53656ca1b2460c4b204"
  },
  {
    "_id": "5df4e53656ca1b2460c4b205",
    "code": "CA",
    "name": "Canada",
    "id": "5df4e53656ca1b2460c4b205"
  },
  {
    "_id": "5df4e53656ca1b2460c4b206",
    "code": "CL",
    "name": "Chile",
    "id": "5df4e53656ca1b2460c4b206"
  },
  {
    "_id": "5df4e53656ca1b2460c4b207",
    "code": "CN",
    "name": "China",
    "id": "5df4e53656ca1b2460c4b207"
  },
  {
    "_id": "5df4e53656ca1b2460c4b208",
    "code": "CO",
    "name": "Colombia",
    "id": "5df4e53656ca1b2460c4b208"
  },
  {
    "_id": "5df4e53656ca1b2460c4b209",
    "code": "CG",
    "name": "Congo",
    "id": "5df4e53656ca1b2460c4b209"
  },
  {
    "_id": "5df4e53656ca1b2460c4b20a",
    "code": "CD",
    "name": "Congo Dr",
    "id": "5df4e53656ca1b2460c4b20a"
  },
  {
    "_id": "5df4e53656ca1b2460c4b20b",
    "code": "CR",
    "name": "Costa Rica",
    "id": "5df4e53656ca1b2460c4b20b"
  },
  {
    "_id": "5df4e53656ca1b2460c4b20c",
    "code": "CI",
    "name": "Cote D'ivoire",
    "id": "5df4e53656ca1b2460c4b20c"
  },
  {
    "_id": "5df4e53656ca1b2460c4b20d",
    "code": "HR",
    "name": "Croatia",
    "id": "5df4e53656ca1b2460c4b20d"
  },
  {
    "_id": "5df4e53656ca1b2460c4b20e",
    "code": "CU",
    "name": "Cuba",
    "id": "5df4e53656ca1b2460c4b20e"
  },
  {
    "_id": "5df4e53656ca1b2460c4b20f",
    "code": "CY",
    "name": "Cyprus",
    "id": "5df4e53656ca1b2460c4b20f"
  },
  {
    "_id": "5df4e53656ca1b2460c4b210",
    "code": "CZ",
    "name": "Czech Republic",
    "id": "5df4e53656ca1b2460c4b210"
  },
  {
    "_id": "5df4e53656ca1b2460c4b211",
    "code": "",
    "name": "Czechoslovakia",
    "id": "5df4e53656ca1b2460c4b211"
  },
  {
    "_id": "5df4e53656ca1b2460c4b212",
    "code": "DK",
    "name": "Denmark",
    "id": "5df4e53656ca1b2460c4b212"
  },
  {
    "_id": "5df4e53656ca1b2460c4b213",
    "code": "DO",
    "name": "Dominican Republic",
    "id": "5df4e53656ca1b2460c4b213"
  },
  {
    "_id": "5df4e53656ca1b2460c4b214",
    "code": "EC",
    "name": "Ecuador",
    "id": "5df4e53656ca1b2460c4b214"
  },
  {
    "_id": "5df4e53656ca1b2460c4b215",
    "code": "EG",
    "name": "Egypt",
    "id": "5df4e53656ca1b2460c4b215"
  },
  {
    "_id": "5df4e53656ca1b2460c4b216",
    "code": "SV",
    "name": "El Salvador",
    "id": "5df4e53656ca1b2460c4b216"
  },
  {
    "_id": "5df4e53656ca1b2460c4b217",
    "code": "EE",
    "name": "Estonia",
    "id": "5df4e53656ca1b2460c4b217"
  },
  {
    "_id": "5df4e53656ca1b2460c4b218",
    "code": "ET",
    "name": "Ethiopia",
    "id": "5df4e53656ca1b2460c4b218"
  },
  {
    "_id": "5df4e53656ca1b2460c4b219",
    "code": "FI",
    "name": "Finland",
    "id": "5df4e53656ca1b2460c4b219"
  },
  {
    "_id": "5df4e53656ca1b2460c4b21a",
    "code": "FR",
    "name": "France",
    "id": "5df4e53656ca1b2460c4b21a"
  },
  {
    "_id": "5df4e53656ca1b2460c4b21b",
    "code": "GA",
    "name": "Gabon",
    "id": "5df4e53656ca1b2460c4b21b"
  },
  {
    "_id": "5df4e53656ca1b2460c4b21c",
    "code": "GA",
    "name": "Gambia",
    "id": "5df4e53656ca1b2460c4b21c"
  },
  {
    "_id": "5df4e53656ca1b2460c4b21d",
    "code": "DE",
    "name": "Germany",
    "id": "5df4e53656ca1b2460c4b21d"
  },
  {
    "_id": "5df4e53656ca1b2460c4b21e",
    "code": "GH",
    "name": "Ghana",
    "id": "5df4e53656ca1b2460c4b21e"
  },
  {
    "_id": "5df4e53656ca1b2460c4b21f",
    "code": "GR",
    "name": "Greece",
    "id": "5df4e53656ca1b2460c4b21f"
  },
  {
    "_id": "5df4e53656ca1b2460c4b220",
    "code": "GT",
    "name": "Guatemala",
    "id": "5df4e53656ca1b2460c4b220"
  },
  {
    "_id": "5df4e53656ca1b2460c4b221",
    "code": "GN",
    "name": "Guinea",
    "id": "5df4e53656ca1b2460c4b221"
  },
  {
    "_id": "5df4e53656ca1b2460c4b222",
    "code": "GW",
    "name": "Guinea-bissau",
    "id": "5df4e53656ca1b2460c4b222"
  },
  {
    "_id": "5df4e53656ca1b2460c4b223",
    "code": "GY",
    "name": "Guyana",
    "id": "5df4e53656ca1b2460c4b223"
  },
  {
    "_id": "5df4e53656ca1b2460c4b224",
    "code": "HT",
    "name": "Haiti",
    "id": "5df4e53656ca1b2460c4b224"
  },
  {
    "_id": "5df4e53656ca1b2460c4b225",
    "code": "HN",
    "name": "Honduras",
    "id": "5df4e53656ca1b2460c4b225"
  },
  {
    "_id": "5df4e53656ca1b2460c4b226",
    "code": "HK",
    "name": "Hong Kong",
    "id": "5df4e53656ca1b2460c4b226"
  },
  {
    "_id": "5df4e53656ca1b2460c4b227",
    "code": "HU",
    "name": "Hungary",
    "id": "5df4e53656ca1b2460c4b227"
  },
  {
    "_id": "5df4e53656ca1b2460c4b228",
    "code": "IS",
    "name": "Iceland",
    "id": "5df4e53656ca1b2460c4b228"
  },
  {
    "_id": "5df4e53656ca1b2460c4b229",
    "code": "IN",
    "name": "India",
    "id": "5df4e53656ca1b2460c4b229"
  },
  {
    "_id": "5df4e53656ca1b2460c4b22a",
    "code": "ID",
    "name": "Indonesia",
    "id": "5df4e53656ca1b2460c4b22a"
  },
  {
    "_id": "5df4e53656ca1b2460c4b22b",
    "code": "IR",
    "name": "Iran",
    "id": "5df4e53656ca1b2460c4b22b"
  },
  {
    "_id": "5df4e53656ca1b2460c4b22c",
    "code": "IQ",
    "name": "Iraq",
    "id": "5df4e53656ca1b2460c4b22c"
  },
  {
    "_id": "5df4e53656ca1b2460c4b22d",
    "code": "IE",
    "name": "Ireland",
    "id": "5df4e53656ca1b2460c4b22d"
  },
  {
    "_id": "5df4e53656ca1b2460c4b22e",
    "code": "IL",
    "name": "Israel",
    "id": "5df4e53656ca1b2460c4b22e"
  },
  {
    "_id": "5df4e53656ca1b2460c4b22f",
    "code": "IT",
    "name": "Italy",
    "id": "5df4e53656ca1b2460c4b22f"
  },
  {
    "_id": "5df4e53656ca1b2460c4b230",
    "code": "JM",
    "name": "Jamaica",
    "id": "5df4e53656ca1b2460c4b230"
  },
  {
    "_id": "5df4e53656ca1b2460c4b231",
    "code": "JP",
    "name": "Japan",
    "id": "5df4e53656ca1b2460c4b231"
  },
  {
    "_id": "5df4e53656ca1b2460c4b232",
    "code": "JO",
    "name": "Jordan",
    "id": "5df4e53656ca1b2460c4b232"
  },
  {
    "_id": "5df4e53656ca1b2460c4b233",
    "code": "KZ",
    "name": "Kazakstan",
    "id": "5df4e53656ca1b2460c4b233"
  },
  {
    "_id": "5df4e53656ca1b2460c4b234",
    "code": "KE",
    "name": "Kenya",
    "id": "5df4e53656ca1b2460c4b234"
  },
  {
    "_id": "5df4e53656ca1b2460c4b235",
    "code": "KP",
    "name": "Korea, Dpr",
    "id": "5df4e53656ca1b2460c4b235"
  },
  {
    "_id": "5df4e53656ca1b2460c4b236",
    "code": "KW",
    "name": "Kuwait",
    "id": "5df4e53656ca1b2460c4b236"
  },
  {
    "_id": "5df4e53656ca1b2460c4b237",
    "code": "LV",
    "name": "Latvia",
    "id": "5df4e53656ca1b2460c4b237"
  },
  {
    "_id": "5df4e53656ca1b2460c4b238",
    "code": "LB",
    "name": "Lebanon",
    "id": "5df4e53656ca1b2460c4b238"
  },
  {
    "_id": "5df4e53656ca1b2460c4b239",
    "code": "LR",
    "name": "Liberia",
    "id": "5df4e53656ca1b2460c4b239"
  },
  {
    "_id": "5df4e53656ca1b2460c4b23a",
    "code": "LY",
    "name": "Libya",
    "id": "5df4e53656ca1b2460c4b23a"
  },
  {
    "_id": "5df4e53656ca1b2460c4b23b",
    "code": "LT",
    "name": "Lithuania",
    "id": "5df4e53656ca1b2460c4b23b"
  },
  {
    "_id": "5df4e53656ca1b2460c4b23c",
    "code": "LU",
    "name": "Luxembourg",
    "id": "5df4e53656ca1b2460c4b23c"
  },
  {
    "_id": "5df4e53656ca1b2460c4b23d",
    "code": "MG",
    "name": "Madagascar",
    "id": "5df4e53656ca1b2460c4b23d"
  },
  {
    "_id": "5df4e53656ca1b2460c4b23e",
    "code": "MW",
    "name": "Malawi",
    "id": "5df4e53656ca1b2460c4b23e"
  },
  {
    "_id": "5df4e53656ca1b2460c4b23f",
    "code": "MY",
    "name": "Malaysia",
    "id": "5df4e53656ca1b2460c4b23f"
  },
  {
    "_id": "5df4e53656ca1b2460c4b240",
    "code": "ML",
    "name": "Mali",
    "id": "5df4e53656ca1b2460c4b240"
  },
  {
    "_id": "5df4e53656ca1b2460c4b241",
    "code": "MT",
    "name": "Malta",
    "id": "5df4e53656ca1b2460c4b241"
  },
  {
    "_id": "5df4e53656ca1b2460c4b242",
    "code": "MX",
    "name": "Mexico",
    "id": "5df4e53656ca1b2460c4b242"
  },
  {
    "_id": "5df4e53656ca1b2460c4b243",
    "code": "MD",
    "name": "Moldova",
    "id": "5df4e53656ca1b2460c4b243"
  },
  {
    "_id": "5df4e53656ca1b2460c4b244",
    "code": "MN",
    "name": "Mongolia",
    "id": "5df4e53656ca1b2460c4b244"
  },
  {
    "_id": "5df4e53656ca1b2460c4b245",
    "code": "MA",
    "name": "Morocco",
    "id": "5df4e53656ca1b2460c4b245"
  },
  {
    "_id": "5df4e53656ca1b2460c4b246",
    "code": "MZ",
    "name": "Mozambique",
    "id": "5df4e53656ca1b2460c4b246"
  },
  {
    "_id": "5df4e53656ca1b2460c4b247",
    "code": "MM",
    "name": "Myanmar",
    "id": "5df4e53656ca1b2460c4b247"
  },
  {
    "_id": "5df4e53656ca1b2460c4b248",
    "code": "NA",
    "name": "Namibia",
    "id": "5df4e53656ca1b2460c4b248"
  },
  {
    "_id": "5df4e53656ca1b2460c4b249",
    "code": "NL",
    "name": "Netherlands",
    "id": "5df4e53656ca1b2460c4b249"
  },
  {
    "_id": "5df4e53656ca1b2460c4b24a",
    "code": "NZ",
    "name": "New Zealand",
    "id": "5df4e53656ca1b2460c4b24a"
  },
  {
    "_id": "5df4e53656ca1b2460c4b24b",
    "code": "NI",
    "name": "Nicaragua",
    "id": "5df4e53656ca1b2460c4b24b"
  },
  {
    "_id": "5df4e53656ca1b2460c4b24c",
    "code": "NE",
    "name": "Niger",
    "id": "5df4e53656ca1b2460c4b24c"
  },
  {
    "_id": "5df4e53656ca1b2460c4b24d",
    "code": "NG",
    "name": "Nigeria",
    "id": "5df4e53656ca1b2460c4b24d"
  },
  {
    "_id": "5df4e53656ca1b2460c4b24e",
    "code": "NO",
    "name": "Norway",
    "id": "5df4e53656ca1b2460c4b24e"
  },
  {
    "_id": "5df4e53656ca1b2460c4b24f",
    "code": "OM",
    "name": "Oman",
    "id": "5df4e53656ca1b2460c4b24f"
  },
  {
    "_id": "5df4e53656ca1b2460c4b250",
    "code": "PK",
    "name": "Pakistan",
    "id": "5df4e53656ca1b2460c4b250"
  },
  {
    "_id": "5df4e53656ca1b2460c4b251",
    "code": "PA",
    "name": "Panama",
    "id": "5df4e53656ca1b2460c4b251"
  },
  {
    "_id": "5df4e53656ca1b2460c4b252",
    "code": "PG",
    "name": "Papua New Guinea",
    "id": "5df4e53656ca1b2460c4b252"
  },
  {
    "_id": "5df4e53656ca1b2460c4b253",
    "code": "PY",
    "name": "Paraguay",
    "id": "5df4e53656ca1b2460c4b253"
  },
  {
    "_id": "5df4e53656ca1b2460c4b254",
    "code": "PE",
    "name": "Peru",
    "id": "5df4e53656ca1b2460c4b254"
  },
  {
    "_id": "5df4e53656ca1b2460c4b255",
    "code": "PH",
    "name": "Philippines",
    "id": "5df4e53656ca1b2460c4b255"
  },
  {
    "_id": "5df4e53656ca1b2460c4b256",
    "code": "PL",
    "name": "Poland",
    "id": "5df4e53656ca1b2460c4b256"
  },
  {
    "_id": "5df4e53656ca1b2460c4b257",
    "code": "PT",
    "name": "Portugal",
    "id": "5df4e53656ca1b2460c4b257"
  },
  {
    "_id": "5df4e53656ca1b2460c4b258",
    "code": "QA",
    "name": "Qatar",
    "id": "5df4e53656ca1b2460c4b258"
  },
  {
    "_id": "5df4e53656ca1b2460c4b259",
    "code": "RO",
    "name": "Romania",
    "id": "5df4e53656ca1b2460c4b259"
  },
  {
    "_id": "5df4e53656ca1b2460c4b25a",
    "code": "RU",
    "name": "Russia",
    "id": "5df4e53656ca1b2460c4b25a"
  },
  {
    "_id": "5df4e53656ca1b2460c4b25b",
    "code": "SA",
    "name": "Saudi Arabia",
    "id": "5df4e53656ca1b2460c4b25b"
  },
  {
    "_id": "5df4e53656ca1b2460c4b25c",
    "code": "SN",
    "name": "Senegal",
    "id": "5df4e53656ca1b2460c4b25c"
  },
  {
    "_id": "5df4e53656ca1b2460c4b25d",
    "code": "YU",
    "name": "Serbia-montenegro",
    "id": "5df4e53656ca1b2460c4b25d"
  },
  {
    "_id": "5df4e53656ca1b2460c4b25e",
    "code": "SL",
    "name": "Sierra Leone",
    "id": "5df4e53656ca1b2460c4b25e"
  },
  {
    "_id": "5df4e53656ca1b2460c4b25f",
    "code": "SG",
    "name": "Singapore",
    "id": "5df4e53656ca1b2460c4b25f"
  },
  {
    "_id": "5df4e53656ca1b2460c4b260",
    "code": "SK",
    "name": "Slovak Republic",
    "id": "5df4e53656ca1b2460c4b260"
  },
  {
    "_id": "5df4e53656ca1b2460c4b261",
    "code": "SI",
    "name": "Slovenia",
    "id": "5df4e53656ca1b2460c4b261"
  },
  {
    "_id": "5df4e53656ca1b2460c4b262",
    "code": "SO",
    "name": "Somalia",
    "id": "5df4e53656ca1b2460c4b262"
  },
  {
    "_id": "5df4e53656ca1b2460c4b263",
    "code": "ZA",
    "name": "South Africa",
    "id": "5df4e53656ca1b2460c4b263"
  },
  {
    "_id": "5df4e53656ca1b2460c4b264",
    "code": "KR",
    "name": "South Korea",
    "id": "5df4e53656ca1b2460c4b264"
  },
  {
    "_id": "5df4e53656ca1b2460c4b265",
    "code": "ES",
    "name": "Spain",
    "id": "5df4e53656ca1b2460c4b265"
  },
  {
    "_id": "5df4e53656ca1b2460c4b266",
    "code": "LK",
    "name": "Sri Lanka",
    "id": "5df4e53656ca1b2460c4b266"
  },
  {
    "_id": "5df4e53656ca1b2460c4b267",
    "code": "SD",
    "name": "Sudan",
    "id": "5df4e53656ca1b2460c4b267"
  },
  {
    "_id": "5df4e53656ca1b2460c4b268",
    "code": "SR",
    "name": "Suriname",
    "id": "5df4e53656ca1b2460c4b268"
  },
  {
    "_id": "5df4e53656ca1b2460c4b269",
    "code": "SE",
    "name": "Sweden",
    "id": "5df4e53656ca1b2460c4b269"
  },
  {
    "_id": "5df4e53656ca1b2460c4b26a",
    "code": "CH",
    "name": "Switzerland",
    "id": "5df4e53656ca1b2460c4b26a"
  },
  {
    "_id": "5df4e53656ca1b2460c4b26b",
    "code": "SY",
    "name": "Syria",
    "id": "5df4e53656ca1b2460c4b26b"
  },
  {
    "_id": "5df4e53656ca1b2460c4b26c",
    "code": "TW",
    "name": "Taiwan",
    "id": "5df4e53656ca1b2460c4b26c"
  },
  {
    "_id": "5df4e53656ca1b2460c4b26d",
    "code": "TZ",
    "name": "Tanzania",
    "id": "5df4e53656ca1b2460c4b26d"
  },
  {
    "_id": "5df4e53656ca1b2460c4b26e",
    "code": "TH",
    "name": "Thailand",
    "id": "5df4e53656ca1b2460c4b26e"
  },
  {
    "_id": "5df4e53656ca1b2460c4b26f",
    "code": "TG",
    "name": "Togo",
    "id": "5df4e53656ca1b2460c4b26f"
  },
  {
    "_id": "5df4e53656ca1b2460c4b270",
    "code": "TT",
    "name": "Trinidad & Tobago",
    "id": "5df4e53656ca1b2460c4b270"
  },
  {
    "_id": "5df4e53656ca1b2460c4b271",
    "code": "TN",
    "name": "Tunisia",
    "id": "5df4e53656ca1b2460c4b271"
  },
  {
    "_id": "5df4e53656ca1b2460c4b272",
    "code": "TR",
    "name": "Turkey",
    "id": "5df4e53656ca1b2460c4b272"
  },
  {
    "_id": "5df4e53656ca1b2460c4b273",
    "code": "AE",
    "name": "Uae",
    "id": "5df4e53656ca1b2460c4b273"
  },
  {
    "_id": "5df4e53656ca1b2460c4b274",
    "code": "UG",
    "name": "Uganda",
    "id": "5df4e53656ca1b2460c4b274"
  },
  {
    "_id": "5df4e53656ca1b2460c4b275",
    "code": "UA",
    "name": "Ukraine",
    "id": "5df4e53656ca1b2460c4b275"
  },
  {
    "_id": "5df4e53656ca1b2460c4b276",
    "code": "GB",
    "name": "United Kingdom",
    "id": "5df4e53656ca1b2460c4b276"
  },
  {
    "_id": "5df4e53656ca1b2460c4b277",
    "code": "US",
    "name": "United States",
    "id": "5df4e53656ca1b2460c4b277"
  },
  {
    "_id": "5df4e53656ca1b2460c4b278",
    "code": "UY",
    "name": "Uruguay",
    "id": "5df4e53656ca1b2460c4b278"
  },
  {
    "_id": "5df4e53656ca1b2460c4b279",
    "code": "VE",
    "name": "Venezuela",
    "id": "5df4e53656ca1b2460c4b279"
  },
  {
    "_id": "5df4e53656ca1b2460c4b27a",
    "code": "VN",
    "name": "Vietnam",
    "id": "5df4e53656ca1b2460c4b27a"
  },
  {
    "_id": "5df4e53656ca1b2460c4b27b",
    "code": "YE",
    "name": "Yemen",
    "id": "5df4e53656ca1b2460c4b27b"
  },
  {
    "_id": "5df4e53656ca1b2460c4b27c",
    "code": "ZM",
    "name": "Zambia",
    "id": "5df4e53656ca1b2460c4b27c"
  },
  {
    "_id": "5df4e53656ca1b2460c4b27d",
    "code": "ZW",
    "name": "Zimbabwe",
    "id": "5df4e53656ca1b2460c4b27d"
  },
  {
    "_id": "5df4e53656ca1b2460c4b27e",
    "code": "PR",
    "name": "Puerto Rico",
    "id": "5df4e53656ca1b2460c4b27e"
  }
];
