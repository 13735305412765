import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';

const token = {
  get() {
    return localStorage.getItem('token');
  },
  save(rawToken) {
    return localStorage.setItem('token', rawToken);
  },
  savePermissions(rawPermisson) {
    return localStorage.setItem('permissions', JSON.stringify(rawPermisson));
  },
  getPermissions() {
    return localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : [];
  },
  hasPermission(role) {
    return this.getPermissions().some(item => (item.resource || '').replace('/', '') === role);
  },
  storeTemporaryData(data) {
    return localStorage.setItem('temp_data', JSON.stringify(data));
  },
  getTemporaryData() {
    return JSON.parse(localStorage.getItem('temp_data'));
  },
  parse(rawToken) {
    return jwtDecode(rawToken);
  },
  data() {
    const rawToken = localStorage.getItem('token');

    if (isEmpty(rawToken)) {
      return {};
    }

    let data = {};
    try {
      data = jwtDecode(rawToken);
    } catch (e) {
      data = {};
    }

    return data;
  },
  reset() {
    localStorage.removeItem('token');
  },
};

export default token;
