import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import { getAbilitySubject, getAbilityAction } from 'utils/helpers';
import ability from 'utils/ability'
import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const mapStateToProps = ({ brands, menu, settings }) => ({
  brand: brands,
  menuData: menu.menuLeftData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
})

@withRouter
@connect(mapStateToProps)
/* eslint-disable */
class MenuLeft extends React.Component {
  state = {
    allMenuData: [],
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  componentDidMount() {
    const { menuData = [] } = this.props
    const allMenuData = [];
    menuData.forEach(item => {
      if (!item.url) {
        const children = [];
        item.children.forEach(element => {
          if (element.children) {
            const natives = [];
            element.children.forEach(native => {
              const subject = getAbilitySubject(native.url);
              const action = getAbilityAction(native.url);
              console.log(subject, action);
              const last = [];
              if (native.children) {
                native.children.forEach(naviee => {
                  const subject = getAbilitySubject(naviee.url);
                  const action = getAbilityAction(naviee.url);
                  if (ability.can(action, subject)) {
                    last.push(naviee);
                  }
                })
              }
              if (last.length > 0) {
                native.children = last;
                natives.push(native);
              }
              if (ability.can(action, subject)) {
                natives.push(native);
              }
            });
            if (natives.length > 0) {
              element.children = natives;
              children.push(element);
            }
          } else {
            const subject = getAbilitySubject(element.url);
            const action = getAbilityAction(element.url);
            if (element.key === 'userDemographics') {
              children.push(element)
            } else if (ability.can(action, subject)) {
              children.push(element);
            }
          }
        });
        if (children.length > 0) {
          item.children = children;
          allMenuData.push(item);
        }
      } else {
        const subject = getAbilitySubject(item.url);
        const action = getAbilityAction(item.url);
        if (ability.can(action, subject)) {
          allMenuData.push(item);
        }
      }
    });
    this.setState({ allMenuData }, () => this.setSelectedKeys(this.props));
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isMenuCollapsed && !newProps.isMobileView) {
      this.setState({
        openedKeys: [],
      })
    }
    this.setSelectedKeys(newProps)
  }

  setSelectedKeys = props => {
    const { allMenuData } = this.state
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(allMenuData, 'children'), [
      'url',
      props.location.pathname,
    ])
    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  onCollapse = (value, type) => {
    const { dispatch, isMenuCollapsed } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })

    this.setState({
      openedKeys: [],
    })
  }

  onOpenChange = openedKeys => {
    store.set('app.menu.openedKeys', openedKeys)
    this.setState({
      openedKeys,
    })
  }

  handleClick = e => {
    const { dispatch, isSettingsOpen } = this.props
    store.set('app.menu.selectedKeys', [e.key])
    // custom action on settings menu item
    if (e.key === 'theme-settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
    this.setState({
      selectedKeys: [e.key],
      // openKeys: e.keyPath,
    })
  }

  generateMenuItems = () => {
    const { allMenuData = [] } = this.state
    const generateItem = item => {
      const { key, title, url, icon, disabled } = item
      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <Link to={url}>
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
          <span className={styles.title}>{title}</span>
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return allMenuData.map(menuItem => {
      if (menuItem.children && !menuItem.inline) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      if (menuItem.children && menuItem.inline) {
        const menuAll = menuItem.children.find(item => item.key.indexOf('all') > -1);
        const menuAdd = menuItem.children.find(item => item.key.indexOf('add') > -1);
        return (
          <Menu.Item key={menuItem.key} disabled={menuItem.disabled}>
            <Link to={menuAll.url}>
              {menuAdd && (
                <Link to={menuAdd.url}>
                  <span className={`icmn icmn-plus ${styles.icon} icon-collapsed-hidden`} />
                </Link>
              )}
              <span className={styles.title}>{menuItem.title}</span>
            </Link>
          </Menu.Item>
        )
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const { isMobileView, isMenuCollapsed, isLightTheme, brand } = this.props
    const menuSettings = isMobileView
      ? {
          width: 256,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
        }
      : {
          width: 256,
          collapsible: true,
          collapsed: isMenuCollapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }

    const menu = this.generateMenuItems()

    return (
      <Sider
        {...menuSettings}
        className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      >
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            {brand.logo.url ?
              <img src={brand.logo.url} alt="Logo" /> :
              isMenuCollapsed ?
                <img
                  src="resources/images/logo_icon.svg"
                  style={{ height: '44px', marginTop: '4px', marginLeft: '2px' }}
                  alt="logo"
                /> :
                <img src="resources/images/logo.svg" alt="logo" />}
          </div>
        </div>
        <Scrollbars
          className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                borderRadius: 'inherit',
                backgroundColor: '#c5cdd2',
                left: '1px',
              }}
            />
          )}
          autoHide
        >
          <Menu
            theme={isLightTheme ? 'light' : 'dark'}
            onClick={this.handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className={styles.navigation}
          >
            {menu}
          </Menu>
        </Scrollbars>
      </Sider>
    )
  }
}

export default MenuLeft
