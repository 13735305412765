import moment from 'moment';
import { DATE_FORMAT } from './constants';

const audienceStreamers = [
  'web',
  'ios',
  'android',
  'android_tv',
  'fire_tv',
  'roku_tv',
];

const videoStreamers = [
  'web',
  'ios',
  'android',
  'android_tv',
  'fire_tv',
  'roku_tv',
  'samsung_tv',
  'mansa',
  'plex',
  'vizio'
];

const downloadStreamers = [
  'ios',
  'android',
  'fire_tv',
  'roku_tv',
];

const statisticTerritories = [
  'us',
  'mx'
];

const mappingTerritory = {
  us: 'United States',
  mx: 'Mexico',
  grand_total: 'Grand Total',
};

const mappingChannel = {
  capulina_collection: 'Capulina Collection',
  oro_tv: 'Oro TV',
  noctvrno: 'Noctvrno',
  classic_comedies: 'Classic comedies',
  comedies_tv_shows: 'Comedies / TV Shows',
  romantic_comedy: 'Romantic comedy',
  best_documentaries: 'Best documentaries',
  saboreartv: 'Saboreartv',
  best_drama: 'Best Drama',
  cocina: 'Cocina',
  diversion: 'Diversión',
  bang_showbiz: 'Bang Showbiz',
  noticias_de_latinoamerica: 'Noticias de Latinoamérica',
  grand_total: 'Grand Total'
}

const mappingStreamer = {
  web: 'Web',
  ios: 'iOS',
  android: 'Android',
  android_tv: 'Android TV',
  fire_tv: 'Fire TV',
  roku_tv: 'Roku TV',
  mansa: 'Mansa',
  vizio: 'Vizio',
  plex: 'Plex',
  samsung_tv: 'Samsung TV',
  grand_total: 'Grand Total',
};

const getChartTerritorySeries = (data, territory, field) => {
  return {
    name: mappingTerritory[territory],
    data: data.filter(item => item.territory === territory).map(item => ({
      x: item.date,
      y: item[field]
    }))
  }
}

const getChartStreamerSeries = (data, streamer, field) => {
  return {
    name: mappingStreamer[streamer],
    data: data.filter(item => item.streamer === streamer).map(item => ({
      x: item.date,
      y: item[field]
    }))
  }
}

const getChartSeries = (data, name, field) => {
  return {
    name,
    data: data.map(item => ({
      x: item.date,
      y: item[field]
    }))
  }
}

const calculatePercentageChange = (newNumber, originalNumber) => (((newNumber - originalNumber) / originalNumber) * 100);

const formatFilterDateRange = dates => `${moment.utc(dates.$gte).format(DATE_FORMAT)} - ${moment.utc(dates.$lt).format(DATE_FORMAT)}`;

export {
  statisticTerritories,
  mappingTerritory,
  audienceStreamers,
  videoStreamers,
  downloadStreamers,
  mappingStreamer,
  mappingChannel,
  getChartStreamerSeries,
  getChartTerritorySeries,
  getChartSeries,
  calculatePercentageChange,
  formatFilterDateRange,
};
