import { all, takeEvery, put } from 'redux-saga/effects'
import store from 'store'
import actions from './actions'

export function* SET_COLLECTION({ payload: { entity, data } }) {
  yield store.set(`app.collections.${entity}`, data);
  yield put({ type: 'collections/SET_STATE', payload: { [entity]: data } })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_COLLECTION, SET_COLLECTION),
  ])
}
