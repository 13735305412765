import { Ability, AbilityBuilder } from '@casl/ability';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.type;
}

const ability = new Ability([], { subjectName });

function defineRulesFor(permissions) {
  const { can, rules } = AbilityBuilder.extract();
  permissions.forEach(item => {
    Object.entries(item).forEach(([key, value]) => {
      if (typeof value === 'boolean' && value === true) {
        can(key, item.subject);
      }
    })
  });
  return rules;
}

export default ability;
export { defineRulesFor };
