const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  VERIFY_OTP: 'user/VERIFY_OTP',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOAD_COLLECTION: 'user/LOAD_COLLECTION',
  LOAD_BRAND: 'user/LOAD_BRAND',
  LOGOUT: 'user/LOGOUT',
}

export default actions
